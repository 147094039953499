@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: OmodaR;
  src: url(./OmodaComponents/assets/fonts/omoda-regular.ttf);
}

@font-face {
  font-family: OmodaB;
  src: url(./OmodaComponents/assets/fonts/omoda-bold.ttf);
} 

@font-face {
  font-family: OmodaL;
  src: url(./OmodaComponents/assets/fonts/omoda-light.ttf);
} 

@font-face {
  font-family: OmodaUL;
  src: url(./OmodaComponents/assets/fonts/omoda-ultralight.ttf);
} 

body {
  margin: 0;
  background-color: white;
  font-family: "OmodaR";
}
code {
  font-family: OmodaL,source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p,div,a,button{
  font-family: "OmodaL";
  text-decoration: none;
  font-weight: bold;
}
h1,h2,h3,h5,h4{
  font-family: "OmodaR";
}
.footerT {
  color: rgb(255, 255, 255);
  margin-top: 21px;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: lighter;
}
.footerText {
  color: rgb(255, 255, 255);
  margin: 10px 0px;

  font-size: 18px;
}
.Oswald
{
  margin: 10px 0px;
  color: black;
  font-size: 25px;
}
.OswaldT
{
  margin: 10px 0px;
  color: rgb(255, 255, 255);
  font-size: 18px;
}